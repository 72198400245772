import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import Head from '../components/head'
import projectStyles from '../pages/project.module.scss'

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      title
      slug
      description {
        raw
        references {
          file {
            url
          }
        }
      }
    }
  }
`

const Project = (props) => {
  const json = JSON.parse(props.data.contentfulProject.description.raw);

  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        const alt = 'nada'
        const url = props.data.contentfulProject.description.references[0].file.url
        return <img alt={alt} src={url} />
      },
      "hyperlink": (node) => {
        console.log(node.data)
        return <a href={node.data.uri} className={projectStyles.link}>Click Here To Check Out The Live Site</a>
      }
    }
  }
  return (
    <Layout>
      <Head title={props.data.contentfulProject.title} />
      <div className={projectStyles.content}>
      {documentToReactComponents(json, options)}
      </div>
    </Layout>
  )
}

export default Project